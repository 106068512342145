<template>
    <div class="DetailsPlan" :class="[!Dark ? '' : 'dark']">
        <div class="plan">
            <img v-if="details.kind == 1" src="@/assets/images/icons/u.png" alt />
            <img v-if="details.kind == 2 && details.type == 1" src="@/assets/images/icons/ci.png" alt />
            <img v-if="details.kind == 2 && details.type == 2" src="@/assets/images/icons/ku.png" alt />
            <img v-if="details.kind == 3" src="@/assets/images/icons/kal.png" alt />
            <div class="details">
                <h4>{{details.plan_title || details.title}}</h4>
                <p v-for="(item,index) in details.details" :key="index">
                    {{item.title}}{{item.num}}{{item.unit}}
                </p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props:{
        details: Object,
        Dark: Boolean
    }
}
</script>

<style lang="less" scoped>
    .DetailsPlan{
        margin-top: 10px;
        width: 100%;
        .plan{
            display: flex;
            img{
                width: 27px;
                height: 27px;
            }
            .details{
                margin-left: 5px;
                h4{
                    font-size: 15px;
                    color: #242424;
                    margin-top: 3px;
                }
                p{
                    font-size: 14px;
                    color: #5c5c5c;
                    margin-top: 5px;
                }
            }
        }
    }
</style>



<style lang="less" scoped>
//  以下代码仅供兼容使用

    @media screen {
        @media (max-width: 1366px) and (min-width: 1024px) {
            .DetailsPlan{
                margin-top: 0px !important;
                .plan{
                    img{
                        width: 15px !important;
                        height: 15px !important;
                    }
                    .details{
                        h4{
                            font-size: 12px !important;
                            width: 80px !important;
                            transform: scale(.8) !important;
                            margin-left: -8px !important;
                            margin-top: -1px !important;
                        }
                        p{
                            font-size: 12px !important;
                            transform: scale(.7) !important;
                            width: 100px !important;
                            margin-top: -5px !important;
                            margin-left: -15px !important;
                        }
                    }
                }
            }
        }
    }

    .DetailsPlan.dark{
        .details{
            h4{
                color: #c1c1c1 !important;
            }
            p{
                color: #c1c1c1 !important;
            }
        }
    }

</style>