<template>
    <div class="planShow" :class="[!Dark ? '' : 'dark']">
        <div class="tipWeek" v-if="from == 'programme'">
            <div 
                class="list" 
                v-for="(item,index) in tempDays"
                @click="changeDate(index)"
                :key="index"
            >
                <p class="week">{{item == 1 ? '第一天' : item == 2 ? '第二天' : item == 3 ? '第三天' : item == 4 ? '第四天' : item == 5 ? '第五天' : item == 6 ? '第六天' : '第七天' }}</p>
            </div>
        </div>
        <div class="tipWeek" v-else>
            <div class="list" v-for="(item,index) in weekList" :key="index">
                <p>
                    {{item.day}}
                    <span>{{item.week}}</span>
                </p>
            </div>
        </div>
        <div class="dayDetails">
            <!--:style="{'width' : 'calc( ' + 100 / weekList.length + '%  - 4px )'}" -->
            <div class="details">
                <template v-if="data.day1.length > 0">
                    <planFor 
                        v-for="(item,index) in data.day1" 
                        :key="index"
                        :details="item"
                        :Dark="Dark"
                    />
                </template>
                <div class="notData" v-else>
                    <div class="center">
                        <img src="@/assets/images/error/empty.png" alt="">
                        <p>暂无数据</p>
                    </div>
                </div>
            </div>
            <div class="details">
                <template v-if="data.day2.length > 0">
                    <planFor 
                        v-for="(item,index) in data.day2" 
                        :key="index"
                        :details="item"
                        :Dark="Dark"
                    />
                </template>
                <div class="notData" v-else>
                    <div class="center">
                        <img src="@/assets/images/error/empty.png" alt="">
                        <p>暂无数据</p>
                    </div>
                </div>
            </div>
            <div class="details">
                <template v-if="data.day3.length > 0">
                    <planFor 
                        v-for="(item,index) in data.day3" 
                        :key="index"
                        :details="item"
                        :Dark="Dark"
                    />
                </template>
                <div class="notData" v-else>
                    <div class="center">
                        <img src="@/assets/images/error/empty.png" alt="">
                        <p>暂无数据</p>
                    </div>
                </div>
            </div>
            <template v-if="weekList.length > 3 ">
                <div class="details">
                    <template v-if="data.day4.length > 0">
                        <planFor 
                            v-for="(item,index) in data.day4" 
                            :key="index"
                            :details="item"
                            :Dark="Dark"
                        />
                    </template>
                    <div class="notData" v-else>
                        <div class="center">
                            <img src="@/assets/images/error/empty.png" alt="">
                            <p>暂无数据</p>
                        </div>
                    </div>
                </div>
                <div class="details">
                    <template v-if="data.day5.length > 0">
                        <planFor 
                            v-for="(item,index) in data.day5" 
                            :key="index"
                            :details="item"
                            :Dark="Dark"
                        />
                    </template>
                    <div class="notData" v-else>
                        <div class="center">
                            <img src="@/assets/images/error/empty.png" alt="">
                            <p>暂无数据</p>
                        </div>
                    </div>
                </div>
                <div class="details">
                    <template v-if="data.day6.length > 0">
                    <planFor 
                        v-for="(item,index) in data.day6" 
                        :key="index"
                        :details="item"
                        :Dark="Dark"
                    />
                    </template>
                    <div class="notData" v-else>
                        <div class="center">
                            <img src="@/assets/images/error/empty.png" alt="">
                            <p>暂无数据</p>
                        </div>
                    </div>
                </div>
                <div class="details">
                    <template v-if="data.day7.length > 0">
                        <planFor 
                            v-for="(item,index) in data.day7" 
                            :key="index"
                            :details="item"
                            :Dark="Dark"
                        />
                    </template>
                    <div class="notData" v-else>
                        <div class="center">
                            <img src="@/assets/images/error/empty.png" alt="">
                            <p>暂无数据</p>
                        </div>
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>

<script>

    import PlanFor from "./PlanFor";
    export default {
        props:{
            data: Object,
            weekList: Array,
            getTemp: Boolean,
            tempDays: Number,
            from: String,
            Dark: Boolean
        },
        components: { PlanFor },
        mounted(){
        }
    }
</script>

<style scoped lang="less">
    .planShow{
        height: ~'calc(100% - 100px)';
        overflow-y: scroll;
    }

    .tipWeek{
        display: flex;
        background: #FBFBFB;
        text-align: center;
        .list{
            width: ~'calc( 100% / 7)';
            p{
                font-size: 30px;
                font-weight: bolder;
                span{
                    font-size: 15px;
                    font-weight: normal;
                    color: #AAAAAA;
                }
            }
        }
    }

    .dayDetails{
        margin-top: 5px;
        display: flex;
        .details{
            width: 100%;
            max-width: ~'calc( (100% - 28px) / 7)';
            background: #f8f8f8;
            margin: 0px 2px;
            padding: 10px;
        }
        .notData{
            display: flex;
            width: 100%;
            height: 100%;
            justify-content: center;
            align-items: center;
            text-align: center;
            img{
                width: 50%;
            }
            p{
                font-size: 14px;
                margin-top: 10px;
                color: #AAAAAA;
            }
        }
    }

</style>

<style lang="less" scoped>
//  以下代码仅供兼容使用

    .planShow.dark{
        .tipWeek{
            background: #0c0c0c;
            p{
                color: #c1c1c1;
                span{
                    color: #c1c1c1 !important;
                }
            }
        }
        .dayDetails{
            .details{
                background: #0c0c0c;
            }
        }
    }

    @media screen {
        @media (max-width: 1366px) and (min-width: 1024px) {
            .tipWeek{
                .list{
                    p{
                        font-size: 24px;
                        span{
                            font-size: 12px;
                        }
                    }
                }
            }
            .dayDetails{
                width: 100%;
                overflow: hidden;
                margin-top: 5px;
                display: flex;
                .details{
                    padding: 5px;
                }
            }
        }
    }

</style>